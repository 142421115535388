<template>
  <div>
    <div class="open_list_box">
      <div v-for="(item, index) in openList.items" :key="index" class="open_list_list" @click="goodsDetailPt(item)">
        <div class="open_list_img">
          <img :src="item.goodsImg" alt="">
          <div class="open_list_img_top">
            <div class="open_list_title">开团免费拿</div>
            <div class="open_list_title_price">¥{{ item.openCount }}</div>
          </div>
        </div>
        <div class="open_list_right_box">
          <div class="open_list_actName">{{ item.actName }}</div>
          <div class="open_list_goodsInfo">{{ item.goodsInfo }}</div>
          <div class="open_list_box_bot">
            <div class="open_list_box_l">
              <div class="open_list_t">已拼{{ item.successCount }}+件</div>
              <div>
                <span class="open_list_teamPrice">¥{{ item.teamPrice }}</span>
                <span class="open_list_originPrice">¥{{ item.originPrice }}</span>
              </div>
            </div>
            <div class="open_list_box_r">
              <div class="open_list_t">{{ item.needer }}人成团</div>
              <div class="open_list_btn" @click="goodsDetailPt(item)">我要开团</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenList',
  props: {
    openList: {
      type: null,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    // 点击到详情
    goodsDetailPt: function(item) {
      this.$router.push({
        path: '/goodsDetailPT',
        query: { uid: window.localStorage.getItem('uid'), id: item.id, goodsId: item.goodsId }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.open_list_box {
  background: #f6f6f6;

  .open_list_list {
    background: #fff;
    border-radius: 0.10667rem;
    margin-top: 0.32rem;
    overflow: hidden;
    padding: 12px;
    box-sizing: border-box;

    .open_list_img {
      width: 140px;
      height: 140px;
      float: left;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .open_list_img_top {
        position: absolute;
        left: 0;
        top: -12px;
        width: 60px;
        height: 50px;
        background-image: linear-gradient(#999065, #f7161e);
        border-radius: 0 0 50% 50%;

        .open_list_title {
          text-align: center;
          font-size: 10px;
          color: #fff;
          margin-top: 7px;
        }

        .open_list_title_price {
          font-size: 18px;
          color: #fff;
          margin-top: 6px;
        }
      }
    }

    .open_list_right_box {
      width: calc(100% - 150px);
      float: left;
      margin: 5px;
      text-align: left;

      .open_list_actName {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
      }

      .open_list_goodsInfo {
        font-size: 10px;
        margin-top: 10px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap

      }

      .open_list_box_bot {
        overflow: hidden;
        margin-top: 30px;
        width: 100%;

        .open_list_t {
          margin-bottom: 10px;
          color: #999;
        }

        .open_list_box_l {
          float: left;

          .open_list_teamPrice {
            color: #e81212;
            font-size: 16px;
          }

          .open_list_originPrice {
            color: #999999;
            text-decoration: line-through;
            margin-left: 5px;
            font-size: 10px;
          }
        }

        .open_list_box_r {
          float: right;

          .open_list_btn {
            background: #e81212;
            color: #fff;
            font-size: 11px;
            border-radius: 6px;
            padding: 5px 8px;
            box-sizing: border-box;
            margin-top: -2px;
          }
        }
      }
    }
  }
}

</style>
