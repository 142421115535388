<template>
  <div class="open_group_box">
    <div class="open_group_top">
      <headSearch :search-out="headGo" />
      <!--拼图商城-->
      <div v-show="isTab==0">
        <openList :open-list="openList" />
      </div>
      <!--我的拼团-->
      <div v-show="isTab==1">
        <myOpenGroup :my-opengroup="myOpenList" @myOpenGroupTab="tabNnbType" />
      </div>
    </div>
    <div class="open_group_bot">
      <div class="open_group_bot_box">
        <div class="open_group_list" :class="isTab==0 ? 'bf' :''" @click="isTab=0,tabBtn(0)">
          <div class="open_img open_group_hover_img" :class="isTab==0 ? 'bf_img' :''" />
          <div>拼团商城</div>
        </div>
        <div class="open_group_list" :class="isTab==1 ? 'bf' :''" @click="isTab=1,tabBtn(1)">
          <div class="open_img open_group_hover_img1" :class="isTab==1 ? 'bf_img1' :''" />
          <div>我的拼团</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headSearch from '@/component/head/search'
import openList from '@/component/openGroup/openList'
import myOpenGroup from '@/component/openGroup/myOpengroup'
import { assembleGoods, myNewAssembleList } from '@/services/api'

export default {
  name: 'OpenGroup',
  components: {
    openList,
    myOpenGroup,
    headSearch
  },
  data() {
    return {
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: '',
        callbackGo: this.callbackGo
      },
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00',
      isTab: 0, // tab 切换
      page: 1, // page
      pageSize: 10, // pageSize
      openList: '', // openList 列表
      myOpenList: '', // myOpenList 列表
      fromTypeId: 0, // 0 全部  1 拼团中  2 拼团成功
      times: '' // 清除全局定时器
    }
  },
  mounted() {
    this.groupList()
    // this.myGroupList()
  },
  methods: {

    callbackGo() {
      this.$router.go(-1)
    },
    // tabNnbType 获取子组件 里的 tab   0 全部  1 拼团中  2 拼团成功
    tabNnbType: function(t) {
      this.fromTypeId = t
      this.myGroupList()
    },
    tabBtn: function(t) {
      // eslint-disable-next-line eqeqeq
      if (t == 0) {
        this.headGo.titleData = '拼团商城'
        this.groupList()
      } else {
        this.myGroupList()
        this.headGo.titleData = '我的拼团'
      }
    },
    // 拼团 列表
    groupList: function() {
      const data = {
        page: this.page,
        pageSize: this.pageSize
      }
      assembleGoods(data).then(res => {
        if (Number(res.code) === 200) {
          this.openList = res.data
        }
      })
    },
    // 我的拼团 列表
    myGroupList: function() {
      const data = {
        fromType: 3, // 来源 1 汇店，2 悦旅汇APP, 3 悦旅汇小程序等
        type: this.fromTypeId, // 0 全部  1 拼团中  2 拼团成功
        uid: window.localStorage.getItem('uid'),
        page: this.page,
        pageSize: this.pageSize
      }
      myNewAssembleList(data).then(res => {
        clearInterval(this.times)
        if (Number(res.code) === 200) {
          this.myOpenList = res.list
          this.times = setInterval(() => {
            this.myOpenList.forEach((item) => {
              item.leftTime--
            })
          }, 1000)

          // let t = res.list.filter(function (data){
          //   var second = data.leftTime;
          //   var hr = Math.floor(second / 3600);
          //   var hrStr = hr.toString();
          //   if (hrStr.length == 1) hrStr = "0" + hrStr;
          //   var min = Math.floor((second - hr * 3600) / 60);
          //   var minStr = min.toString();
          //   if (minStr.length == 1) minStr = "0" + minStr;
          //   var sec = second - hr * 3600 - min * 60;
          //   var secStr = sec.toString();
          //   if (secStr.length == 1) secStr = "0" + secStr;
          //   var t = hrStr + ':' + minStr + ':' +secStr
          //   data.newDataTime=t
          //   return  data
          // })
          // this.myOpenList=t
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.open_group_box {
  background: #f6f6f6;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);

  .open_group_top {
    width: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
    background: #f6f6f6;
  }

  .open_group_bot {
    width: 100%;
    height: 72px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;

    .open_group_bot_box {
      display: flex;

      .open_group_list {
        flex: 1;

        .open_img {
          width: 28px;
          height: 28px;
          margin: 6px auto;
        }

        .open_group_hover_img {
          background: url("../../assets/images/shangcheng.png") no-repeat;
          background-size: 100% 100%;
        }

        .open_group_hover_img1 {
          background: url("../../assets/images/wode.png") no-repeat;
          background-size: 100% 100%;
        }

        .open_group_hover_img.bf_img {
          background: url('../../assets/images/shangcheng1.png') no-repeat;
          background-size: 100% 100%;
        }

        .open_group_hover_img1.bf_img1 {
          background: url('../../assets/images/wode1.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .open_group_list.bf {
        color: red;
      }
    }
  }

}

</style>
