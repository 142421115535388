<template>
  <div class="my_open_group_box">
    <div class="my_open_group_tab">
      <!-- tab 暂时不循环 写死的 -->
      <div class="my_tab_list" :class="tabIndex==0 ? 'bf' : ''" @click="tabBtn(0)">全部</div>
      <div class="my_tab_list" :class="tabIndex==1 ? 'bf' : ''" @click="tabBtn(1)">拼团中</div>
      <div class="my_tab_list" :class="tabIndex==2 ? 'bf' : ''" @click="tabBtn(2)">拼团成功</div>
    </div>
    <div>
      <div>
        <div v-for="(item,key) in myOpengroup" :key="key" class="open_list_list">
          <div class="open_list_bot_ov">
            <div class="open_list_box_top">
              <span class="open_list_leftTime">
                <span v-if="item.status != 3">活动剩余{{ leftTime(item.leftTime) }}</span>
                <span v-else>{{ item.endTime }}</span>
              </span>
              <!--拼团状态0:待开团(表示已下单但是未支付)1:已经开团(团长已支付)2:拼团成功,3拼团失败-->
              <span
                class="open_list_r_title"
              >{{ item.status == 0 ? '待开团' : (item.status == 1 ? '拼团中...' : (item.status == 2 ? '拼团成功' : item.status == 3 ? '拼团失败' : '')) }}</span>
            </div>
            <div class="open_list_img">
              <img :src="item.goodImg" alt="">
              <div class="open_list_img_top">
                <div class="open_list_title">{{ item.type == 1 ? '团长' : '团员' }}</div>
              </div>
            </div>
            <div class="open_list_right_box">
              <div class="open_list_actName">{{ item.goodsName }}</div>
              <div class="open_list_goodsInfo">{{ item.goodsInfo }}</div>
              <div class="open_list_box_bot">
                <div class="open_list_box_l">
                  <div>
                    <span class="open_list_teamPrice">¥{{ item.teamPrice }}</span>
                    <span class="open_list_originPrice">¥{{ item.originPrice }}</span>
                  </div>
                </div>
                <div class="open_list_box_r">
                  <div class="open_list_btn" @click="btnLookGo(item)">查看详情</div>
                </div>
              </div>
            </div>
          </div>
          <!--            <div class="open_list_look" v-if="item.status != 3">邀请好友拼团</div>-->
        </div>
        <div v-if="myOpengroup.length<=0" class="open_list_no">暂无信息</div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable eqeqeq */
export default {
  name: 'MyOpengroup',
  components: {},
  props: {
    myOpengroup: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      tabIndex: 0, // tab 0 全部 1拼团中 2拼团成功
      uid: window.localStorage.getItem('uid')
    }
  },
  mounted() {

  },
  methods: {
    // 父传 type 0 全部 1拼团中 2拼团成功
    tabBtn: function(t) {
      this.tabIndex = t
      this.$emit('myOpenGroupTab', t)
    },
    // 点击到详情
    btnLookGo: function(item) {
      this.$router.push({
        path: '/openGroupList',
        query: { uid: this.uid, ordersn: item.ordersn, type: item.type }
      })
    },
    // 时间转化
    leftTime: function(t) {
      var second = t
      var hr = Math.floor(second / 3600)
      var hrStr = hr.toString()
      if (hrStr.length == 1) hrStr = '0' + hrStr
      var min = Math.floor((second - hr * 3600) / 60)
      var minStr = min.toString()
      if (minStr.length == 1) minStr = '0' + minStr
      var sec = second - hr * 3600 - min * 60
      var secStr = sec.toString()
      if (secStr.length == 1) secStr = '0' + secStr
      return hrStr + ':' + minStr + ':' + secStr
    }

  }
}
</script>

<style lang="less" scoped>
.my_open_group_box {
  text-align: left;
  background: #f6f6f6;

  .my_open_group_tab {
    display: flex;
    width: 100%;
    height: 46px;
    line-height: 46px;
    background: #fff;

    .my_tab_list {
      flex: 1;
      text-align: center;
      position: relative;
    }

    .my_tab_list.bf:before {
      content: "";
      position: absolute;
      width: 40%;
      height: 2px;
      background: red;
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
    }

    .my_tab_list.bf {
      color: red;
      font-weight: 700;
    }
  }

  .open_list_list {

    .open_list_bot_ov {
      overflow: hidden;
    }

    background: #fff;
    border-radius: 6px;
    overflow: hidden;
    padding: 12px;
    box-sizing: border-box;
    width: 94%;
    margin: 12px auto;

    .open_list_box_top {
      overflow: hidden;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      margin-bottom: 12px;
      line-height: 36px;

      .open_list_leftTime {
        float: left;
      }

      .open_list_r_title {
        float: right;
      }
    }

    .open_list_img {
      width: 140px;
      height: 140px;
      float: left;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .open_list_img_top {
        position: absolute;
        left: -12px;
        top: 0px;
        width: 72px;
        height: 22px;
        background: #f7161e;
        border-radius: 4px;

        .open_list_title {
          text-align: center;
          font-size: 10px;
          color: #fff;
          line-height: 22px;
        }
      }
    }

    .open_list_right_box {
      width: calc(100% - 150px);
      float: left;
      margin: 5px;
      text-align: left;

      .open_list_actName {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
      }

      .open_list_goodsInfo {
        font-size: 10px;
        margin-top: 10px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }

      .open_list_box_bot {
        overflow: hidden;
        margin-top: 38px;
        width: 100%;

        .open_list_box_l {
          float: left;

          .open_list_teamPrice {
            color: #e81212;
            font-size: 16px;
          }

          .open_list_originPrice {
            color: #999999;
            text-decoration: line-through;
            margin-left: 5px;
            font-size: 10px;
          }
        }

        .open_list_box_r {
          float: right;

          .open_list_btn {
            background: #e81212;
            color: #fff;
            font-size: 10px;
            border-radius: 4px;
            padding: 5px 10px;
            box-sizing: border-box;
          }
        }
      }
    }

    .open_list_look {
      width: 80%;
      height: 36px;
      background: red;
      margin: 12px auto 0;
      border-radius: 24px;
      text-align: center;
      line-height: 36px;
      color: #fff;
      font-size: 16px;
    }
  }

  .open_list_no {
    text-align: center;
    margin-top: 12px;
  }
}

</style>
